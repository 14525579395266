/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// global css
require('normalize.css')
require('./src/prism-theme.css')
require('prismjs/plugins/line-numbers/prism-line-numbers.css')
